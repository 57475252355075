@import "../../../themes.scss";

  .table {
    width: 100%;
    border-collapse: separate;
    display: table;
    overflow-x: auto;
    white-space: nowrap;
    border: none;
    .body {
      box-shadow: none;
      box-shadow: none;

      .body-row {
        .body-start{
          margin-left: 20px;
        }

        .body-title {
          padding: 0px;
          // border: none;

          .status {
            font-weight: bold;
            color: $primary-color;
          }
        }
      }
    }

  
  }

