@import '../../../themes.scss';

.suspend {
    .suspend-btn {
        background-color: $primary-color;
        color: #fff;
        border-radius: 10px !important;
        padding: 8px 30px;

        &:hover {
            background-color: $primary-color;
        }

        .active {
            background-color: #d1e3ff;

            .title {
                color: $primary-color !important;
            }
        }

    }

}