@import "../themes.scss";

.log-out-style {
  .MuiPaper-root {
    border-radius: 12px !important;
  }
}

.drawer-menu {
  .MuiDrawer-paper {
    background-size: 500%;
    background-position: 50% 50%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: 0px 20px 10px rgba(112, 77, 241, 0.25);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 30%,
      rgba(221, 233, 243, 1) 100%
    );
  }

  .image-container {
    background-color: #fff;
    height: 170px;
  }

  .list-menu {
    .list-item-active {
      border-left: 4px solid $primary-color;
      background-color: $background-menu-active;
      display: block;

      .list-item-button {
        max-height: 50px;
        justify-content: initial;

        .list-item-icon {
          justify-content: center;
          color: $primary-color;
          height: 100%;

          .icon {
            width: 24px;
            height: 24px;
          }
        }

        .list-item-text {
          color: $primary-color;
          font-size: $font-size-subtitle;
          padding-top: 2px;
        }
      }
    }

    .list-item {
      display: block;

      .list-item-button {
        max-height: 50px;
        justify-content: initial;

        .list-item-icon {
          justify-content: center;
          color: $text-menu-not-active;
          height: 100%;

          .icon {
            width: 24px;
            height: 24px;
          }
        }

        .list-item-text {
          color: $text-menu-not-active;
          font-size: $font-size-subtitle;
          padding-top: 2px;
        }
      }
    }
  }
}
