@import "../themes.scss";

.forgot-page {
  width: 100%;
  height: 100vh;
  // position: fixed;
  background-color: $background-menu;

  .background-image {
    background-image: url("../Assets/angkor-picture.png");
    background-repeat: no-repeat;
    // background-size: 80%;
    background-size: 100%;
    background-position: 50% 50%;
    position: fixed;
    // opacity: 0.2;
    // filter: blur(1px);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .text-field {
    background-color: #fff;
    border-color: $primary-color;
    border-radius: 20px;

    .MuiOutlinedInput-input {
      color: black !important;
    }
    .MuiInputLabel-root {
      color: black !important;
      // border-radius: 20px;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color;
      border-radius: 20px;
    }
    :hover .MuiOutlinedInput-input {
      color: #fff;
    }
    :hover .MuiInputLabel-root {
      color: black;
      border-radius: 20px;
    }
    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color;
      border-radius: 20px;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
      border-radius: 20px;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color;
      border-radius: 20px;
    }
  }
  .back-btn {
    height: 10%;

    .text-back {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .icon-arrow{
        color: $primary-color;
        font-weight: bold;
      }

      .title-back {
        text-transform: none;
        border-radius: 10px;
      }
    }
  }

  .container {
    width: 100%;
    height: 85%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;

    .form-forgot {
      background-color: #fff;
      padding: 20px;
      width: 300px;
      border-radius: 10px;

      .box-logo {
        display: flex;
        justify-content: center;
        .icon-lock{
          color: $primary-color;
          font-size: 60px;
        }
      }

   
      .box-text {
        .title-login {
          .title {
            color: #000;
            font-weight: bold;

            @include mobile-large {
              font-size: 14px;
              font-weight: 500;
            }
          }
        
          .sub-title {
            color: #000;
          }
        }
      }

      .box-login {
        .icon {
          color: $primary-color;
        }

     

        .btb-sign-in {
          background-color: $primary-color !important;
          color: #fff;
          font-weight: bold;
          border-radius: 20px;
          // font-size: 18px;
        }
      }
    }
  }
}
