@import "../../../../themes.scss";

.update-icon {
  color: $primary-color;
}
.delete-icon {
  color: red;
}
.update-title {
  font-family: $font-title;
  font-size: $font-size-subtitle;
}
