@import "../../../themes.scss";

.setup-dialog {
  .MuiPaper-root {
    border-radius: 12px !important;
    width: 500px !important;
  }

  .dialog-title {
    .title {
      font-size: $font-size-title;
      color: $primary-color;
      font-weight: bold;
    }
    .clear-icon {
      color: red;
    }
  }

  .titleKh {
    font-family: $font-siemreap;
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold;
  }

  .titleEn {
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold;
  }

  .grid-profile {
    .profile {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .avater-image {
        width: 140px;
        height: 140px;
      }
    }
    .text-Kh {
      font-family: $font-siemreap;
      font-size: $font-size-subtitle;
      font-weight: bold;
      color: $primary-color;
    }

    .text-En {
      font-size: $font-size-subtitle;
      font-weight: bold;
      color: $primary-color;
    }
  }

  .subKh {
    font-family: $font-siemreap;
    font-size:$font-size-body;
  }

  .subEn {
    font-size:$font-size-body;
  }
  .action {
    padding: 0px 16px 16px 16px;
    width: 100%;
    .btn-actionKh {
      width: 100%;
      background-color: $primary-color;
      color: #fff;
      font-family: $font-siemreap;
    }

    .btn-actionEn {
      width: 100%;
      background-color: $primary-color;
      color: #fff;
    }
  }

  .icon{
    cursor: pointer;
  }
}
