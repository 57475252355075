@import "../themes.scss";

.top-bar {
  // background-color: #fff;
  // border-radius: 10px;
  // padding: 0px 20px;

  .language-flag{
    width: 35px;
    height: 25px;
    object-fit: cover !important;
    overflow: hidden !important;
    border-radius: 4px !important;
  }

  .icon-right {
    width: 26px;
    height: 26px;
    color: gray;
  }
  .user-name {
    font-size: $font-size-body;
    color: $primary-color;
    font-weight: bold;
  }
  .user-role {
    font-size: 12px;
    color: gray;
  }
}
