@import "../../../../themes.scss";

.preview-discount {
  .MuiPaper-root {
    border-radius: 12px !important;
  }

  .dialog-title {
    .title {
      font-size: $font-size-title;
      color: $primary-color;
      font-weight: bold;
    }

    .clear-icon {
      color: red;
    }
  }

  .content {
    @include mobile-large {
      flex-direction: column;
    }

    @include mobile {
      flex-direction: column;
    }

    @include mobile-small {
      flex-direction: column;
    }

    .image {
      width: 150px;
      height: 150px;
    }

    .body {
      .body-cell {
        padding: 5px 0px;
        border-bottom: none;
        color: $primary-color;
        font-weight: bold;
        font-size: $font-size-body;
      }

      .data {
        max-width: 150px;
        overflow-wrap: anywhere;
        padding: 5px 0px;
        border-bottom: none;
        font-weight: bold;
        font-size: $font-size-body;
      }

      .second-table {
        .head {
          .header-cell {
            background-color: $primary-color;
            padding: 8px 5px;
            color: #fff;
            font-weight: bold;
            border: none;
            font-size: $font-size-body !important;
          }
        }

        .body {
          .sec-body-cell {
            font-size: $font-size-body;
            background-color: #58b0dd36;
            padding: 7px 5px;
          }
        }
      }
    }
  }
}
