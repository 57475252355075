@import "../themes.scss";

.dashboard-page {
  .page-title {
    margin-top: 40px;
    // font-family: $font-title;
    color: $page-title-color;
    font-size: $font-size-title;
    font-weight: bold;
  }

  .page-des{
    font-family: $font-title;
    color: $page-title-color;
    font-size: $font-size-body;
  }

  .box-container {
    margin-top: 80px;
  }
}
