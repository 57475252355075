@import "../../themes.scss";

.preview-expense {
    .MuiPaper-root {
        border-radius: 12px !important;
        // width: 500px !important;
    }

    .dialog-title {
        padding: 10px !important;

        .title {
            font-size: $font-size-title;
            color: $primary-color;
            font-weight: bold;
        }

        .clear-icon {
            color: red;
        }
    }

    .titleKh {
        font-family: $font-siemreap;
        color: $primary-color;
        font-size: $font-size-title;
        font-weight: bold;
    }

    .titleEn {
        font-family: $font-title;
        color: $primary-color;
        font-size: $font-size-title;
        font-weight: bold;
    }

    .content {
        position: relative;

        .voided {
            border: 5px solid #ff000073;
            border-radius: 15px;
            padding: 10px;
            position: absolute;
            font-size: 50px;
            font-weight: bold;  
            color: #ff000073;
            top: 20%;
            left: 35%;
            transform: translate(-50%, -50%);
            -webkit-transform: rotate(-35deg);
            -moz-transform: rotate(-35deg);
            -o-transform: rotate(-35deg);
            writing-mode: lr-tb;
        }

        .title {
            color: $primary-color;
            font-weight: bold;
        }

        .header-cell {
            background-color: $primary-color;
            color: #fff;
            font-weight: bold;
            padding: 5px 4px;
        }

        .body-cell {
            border: none;
            padding: 5px 4px;
        }

        .footer {
            border: none;
            padding: 5px 4px;
        }
    }

}