@import "../themes.scss";

.main {
  position: relative;
  width: 100%;
  // background-color: #e3f2fd;
}

.layout-container {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  // height: 100vh;
  background-color: $primary-background !important;

  @include mobile-small {
    width: 320px !important;
  }
  @include mobile {
    width: 480px !important;
  }
  @include mobile-large {
    width: 550px !important;
  }
  @include tablet {
    width: 768px !important;
  }

  .outlet-container {
    background-repeat: no-repeat;
    background-image: url("../Assets/angkor-picture.png");
    background-size: 800px;
    background-position: 46.5% -28%;
    // overflow-y: scroll;

    @include mobile-small {
      width: 500px !important;
    }

    @include mobile {
      width: 530px !important;
    }

    @include mobile-large {
      width: 550px !important;
    }

    @include tablet {
      width: 1000px !important;
    }
  }
}

  .backdrop-style {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 30%,
      rgba(221, 233, 243, 1) 100%
    );

    .background-image {
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: 10% 50%;
      position: fixed;
      opacity: 0.1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .system-title {
      margin-top: 10px;
      // margin-bottom: 10px;
      color: $secondary-color;
      font-size: 20px;
      font-weight: bold;
    }

    .power-title {
      position: fixed;
      bottom: 20px;
      font-weight: bold;
      color: $secondary-color;
    }
  }
