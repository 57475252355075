@import "../themes.scss";

.print-btn {
  background-color: $primary-color !important;
  color: #fff !important;
  margin-top: 3px !important;
  border-radius: 10px !important;
  font-weight: bold !important;
}


//WRAP ALL REPORT TYPE
  .report-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    font-size: 11px;

    // height: 100%;
    // overflow-y: scroll;
    // overflow-x: hidden; 

    .text-field {
      width: 100%;
      border-radius: 10px;
      background-color: #fff !important;
      color: black;
    }

    .report-intro {
      justify-content: center;
      align-items: center;
      position: relative;

      .logo {
        position: absolute;
        top: 10px;
        left: 40px;
        width: 110px !important;
      }

      .nation {
        font-family: "KhmerOsMuol";
        // font-weight: bold;
        font-size: 12px;
      }

      .wingdingImage {
        width: 150px !important;
      }
    }

    .report-title {
      font-family: "KhmerOsMuol";
      font-size: 12px !important;
    }

    .report-date {
      font-family: "Siemreap";
      font-size: 12px !important;
    }
    
    

    .center {
      text-align: center !important;
    }

    table {
      width: 100%;
      border-collapse: collapse !important;
    }
    table thead tr th {
      border: 0.8px solid #b2cff0 !important;
      background-color: #cfe1f8;
      font-weight: normal;
      vertical-align: center;
      padding-top: 4px;
      padding-bottom: 4px;
    }
    table tbody tr td {
      border: 0.8px solid #b2cff0 !important;
      font-weight: normal;
      vertical-align: center;
      padding-top: 3px;
      padding-bottom: 3px;
    }
  
  }

