@import "../../../../themes.scss";

.wholesale-page {
  .slash {
    width: 4px;
    height: 50px;
    background-color: $primary-color;
  }

  .page-title {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold;
  }

  .search-container {
    display: flex;
    justify-content: left;

    .box-search {
      .text-field {
        background-color: #fff;
        border-radius: 10px;
        font-family: $font-title;

        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
          border-color: #fff;
          font-family: $font-title;
          border-radius: 10px;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
          color: black;
          font-family: $font-title;
          border-radius: 10px;
        }

        .MuiInputLabel-root.Mui-focused {
          color: black;
          font-family: $font-title;
          border-radius: 10px;
        }
      }
    }
  }

  .add-container {
    display: flex;
    justify-content: right;

    .btn-add {
      background-color: $primary-color;
      color: #fff;
      border-radius: 10px;
      padding: 0px 30px;
    }
  }

  .box-table-container {
    margin-top: 20px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px 20px 20px 20px;

    @include mobile-small {
      width: 310px !important;
    }

    @include mobile {
      width: 370px !important;
    }

    @include mobile-large {
      width: 476px !important;
    }

    .table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 4px;

      .header-row {
        .header-title {
          padding: 10px;
          // border: none;
          font-family: $font-title;
          font-size: $font-size-subtitle;
          font-weight: bold;
          color: $primary-color;
          border-bottom: 1px solid $primary-color !important;
        }
      }

      .body-row {

        .body-cell {
          cursor: pointer;
          border-bottom: 0.2px solid $page-title-color !important;
          // border: none;
          font-size: $font-size-body;
          padding: 6px 10px;
        }
      }
    }
  }
}