@import "../../themes.scss";

.header-container {
  // ================================ first block =============================
  .stack-container-first {
    background-image: url("../../Assets/icon_dashboard/First_share.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0% 50%;
    background-color: #6862c2;
    color: #fff;
    border-radius: 10px;
    padding: 30px 20px;

    .front-icon {
      width: 80%;
    }

    .share-number {
      font-size: 26px;
    }
    .share-des {
      font-size: $font-size-subtitle;
      font-weight: bold;
    }
  }

  // ================================ second block =============================
  .stack-container-second {
    background-image: url("../../Assets/icon_dashboard/Second_share.png");
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: 95% 80%;
    background-color: #e1505d;
    color: #fff;
    border-radius: 10px;
    padding: 30px 20px;

    .front-icon {
      width: 80%;
    }

    .share-number {
      font-size: 26px;
    }
    .share-des {
      font-size: $font-size-subtitle;
      font-weight: bold;
    }
  }

  // ================================ third block =============================
  .stack-container-third {
    background-image: url("../../Assets/icon_dashboard/Third_share.png");
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: 100% 50%;
    background-color: #dfac2f;
    color: #fff;
    border-radius: 10px;
    padding: 30px 20px;

    .front-icon {
      width: 80%;
    }

    .share-number {
      font-size: 26px;
    }
    .share-des {
      font-size: $font-size-subtitle;
      font-weight: bold;
    }
  }

  // ================================ fourth block =============================
  .stack-container-fourth {
    background-image: url("../../Assets/icon_dashboard/Fourth_share.png");
    background-repeat: no-repeat;
    background-size: 28%;
    background-position: 90% 50%;
    background-color: #2a9ada;
    color: #fff;
    border-radius: 10px;
    padding: 30px 20px;

    .front-icon {
      width: 80%;
    }

    .share-number {
      font-size: 26px;
    }
    .share-des {
      font-size: $font-size-subtitle;
      font-weight: bold;
    }
  }
}
