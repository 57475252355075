@import "../../../themes.scss";

.btn-payment {
  //   text-transform: none;
  background-color: $primary-color !important;
  color: #fff !important;

  .icon {
    font-size: 40px;
  }
}
.recent-payment {
  font-weight: bold !important;
  font-size: $font-size-title !important;
}

.list-payment {
  font-weight: bold !important;
  background-color: #daeaf3;
  border-radius: 5px;

  .pay-method {
    padding: 10px 30px;
    background-color: $primary-color;
    color: #fff;
    border-radius: 5px;
    width: 150px;
    text-align: center;
  }
  
}

.btn-void-payment-card {
  // background-color: $primary-color !important;
  color: red !important;
  font-weight: bold !important;

}

// CASH ==========================
.cash {
  .title {
    font-family: $font-title;
    color: $primary-color;
  }

  .amount {
    font-size: 20px;
  }

  .money {
    font-size: 35px;
  }

  .less {
    background-color: rgb(255, 166, 166) !important;
  }
  .more {
    background-color: rgb(198, 255, 183) !important;
  }

  .process-btn {
    background-color: $primary-color;
    color: #fff;
    padding-left: 55px;
    padding-right: 55px;

    &:hover {
      background-color: $primary-color;
    }
  }
}
// SPLIT ==========================
.split {
  .title {
    font-family: $font-title;
    color: $primary-color;
  }

  .amount {
    font-size: 20px;
  }

  .money {
    font-size: 35px;
  }
  .payment {
    padding-left: 15px;
    border: 1px solid $primary-color;
  }

  .btn {
    background-color: $primary-color;
    border-radius: 0px;
    color: #fff;
    &:hover {
      background-color: $primary-color;
    }
  }
  .disable {
    color: black !important;
  }
}
