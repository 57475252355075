@import "../themes.scss";

.page-container {
  width: 100%;
  height: 100%;
  // overflow-y: auto;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  .link {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold;
    text-decoration: none;
    background-color: #fff;
    cursor: pointer;
  }

  .slash {
    border: 2px solid $primary-color;
    height: 50px;
  }

  .page-title {
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold;
  }

  .title-active {
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold;
  }

  .btn-add {
    background-color: $primary-color !important;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    padding: 0px 30px;
  }

  .border-mash {
    background-color: $mask-primary;
    padding: 10px;
    display: flex;
    border-radius: 10px;
    width: 100%;

    .text {
      margin-top: 10px;
      color: $primary-color;

      .bold-tage {
        font-weight: bold;
      }
    }
  }

  .shop {
    background-color: #fff !important;
    border-radius: 10px;
    padding: 10px;
    color: $primary-color;

    .select {
      background-color: #fff;
      border-radius: 10px;
      width: 250px;
    }

    .btn-delivery {
      background-color: $primary-color;
      border-radius: 10px;
      color: #fff;
      padding: 8px 20px;
      font-weight: bold;
    }

    @include mobile-small {
      flex-direction: column;
      justify-content: start;
    }

    @include mobile {
      flex-direction: column;
      justify-content: start;
    }

    @include mobile-large {
      flex-direction: column;
      justify-content: start;
    }

    @include tablet {
      flex-direction: column;
      justify-content: start;
      row-gap: 10px;
    }
  }

  .btn-add-list {
    padding: 4px 10px;
    border-radius: 10px;
    background-color: $primary-color !important;
    color: #fff;
  }

  .text-field {
    width: 100%;
    border-radius: 10px;
    font-family: $font-title;
    background-color: #fff;

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      font-family: $font-title;
      border-radius: 10px;
      border-color: #fff;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
      font-family: $font-title;
      border-radius: 10px;
    }

    .MuiInputLabel-root.Mui-focused {
      color: black;
      font-family: $font-title;
      border-radius: 10px;
    }
  }

  .search-container {
    display: flex;
    justify-content: left;
    color: black;

    .box-search {
      // @include mobile-small {
      //   width: 150px !important;
      // }

      // @include mobile {
      //   width: 200px !important;
      // }

      // @include mobile-large {
      //   width: 250px !important;
      // }

      // @include tablet {
      //   width: 300px !important;
      // }
      .text-field {
        background-color: $mask-primary;
        border-radius: 10px;
        font-family: $font-title;

        .MuiOutlinedInput-input {
          font-family: $font-title;
          border-radius: 10px;
        }

        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
          color: $primary-color;
          font-family: $font-title;
          border-radius: 10px;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
          font-family: $font-title;
          border-radius: 10px;
        }

        .MuiInputLabel-root.Mui-focused {
          font-family: $font-title;
          border-radius: 10px;
        }
      }
    }
  }

  .btn-add {
    background-color: $primary-color;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
  }

  .btn-set {
    border-radius: 10px;
    color: $primary-color;
    background-color: #fff;
    border: none !important;
    padding: 15px;
    display: flex-start !important;
    flex-direction: row !important;
    width: 100%;
    text-transform: none !important;

    &:hover {
      border: none !important;
      background-color: #fff;
    }

    .stack-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      .icon-box {
        .icon {
          font-size: 30px;
          margin-left: 5px;
        }
      }

      .title {
        text-align: start;
      }
    }
  }

  .body-container {
    margin-top: 20px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 5px 20px 20px 20px;

    .table {
      flex-direction: row;
      // border-radius: 0px 12px 12px 12px;
      border-radius: 10px;
      overflow-x: auto;
      white-space: nowrap;
      overflow-y: scroll;

      // min-width: 700px;
      // @include desktop {
      //   width: 1600px !important;
      // }
      // @include laptop {
      //   width: 1300px !important;
      // }
      // @include tablet {
      //   width: 1000px !important;
      // }

      @include mobile-small {
        width: 310px !important;
      }

      @include mobile {
        width: 370px !important;
      }

      @include mobile-large {
        width: 476px !important;
      }

      .header-row {
        border-radius: 10px 10px 0px 0px;

        //Khmer
        .header-titleKh-start {
          border-radius: 10px 0px 0px 0px;
          font-family: $font-siemreap;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
          border-bottom: 1px solid $primary-color;
        }

        .header-titleKh {
          font-family: $font-siemreap;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
          border-bottom: 1px solid $primary-color;
        }

        .header-titleKh-end {
          border-radius: 0px 10px 0px 0px;
          font-family: $font-siemreap;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
          border-bottom: 1px solid $primary-color;
        }

        //English
        .header-title-start {
          border-radius: 10px 0px 0px 0px;
          font-family: $font-title;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
          border-bottom: 1px solid $primary-color;
        }

        .header-title {
          // padding: 14px 20px;
          // font-family: $font-title;
          font-size: $font-size-body;
          font-weight: bold;
          color: $primary-color;
          border-bottom: 1px solid $primary-color;
        }

        .header-title-end {
          border-radius: 0px 10px 0px 0px;
          font-family: $font-title;
          font-size: $font-size-body;
          font-weight: bold;
          border-bottom: 1px solid $primary-color;
          color: $primary-color;
        }
      }

      .body-row {
        &:hover{
          background-color: rgba(244, 244, 244, 0.315);
        }
        cursor: pointer;
        border-bottom: 0.2px solid $page-title-color;

        //Khmer
        .body-cellKh-start {
          font-family: $font-siemreap;
          font-size: $font-size-body;
          padding: 6px 0px 6px 20px;
        }

        .body-cellKh {
          font-family: $font-siemreap;
          font-size: $font-size-body;
          padding: 6px 20px;
        }

        .body-cellKh-end {
          font-family: $font-siemreap;
          font-size: $font-size-body;
          padding: 6px 30px 6px 0px;
        }

        //English
        .body-cell-start {
          font-size: $font-size-body;
          padding: 6px 0px 6px 20px;
        }

        .body-cell {
          font-size: $font-size-body;
          padding: 6px 20px;
        }

        .body-cell-end {
          font-size: $font-size-body;
          padding: 6px 30px 6px 0px;
        }
      }

      .void{
        
        background-color: #ff000017;
        cursor: pointer;

        &:hover{
          background-color: #ff000017;
        }
      }

      .body-row-gray {
       
        &:hover{
          background-color: rgba(244, 244, 244, 0.315);
        }
        cursor: pointer;

        //Khmer
        .body-cellKh-start {
          font-family: $font-siemreap;
          font-size: $font-size-body;
          padding: 6px 0px 6px 20px;
        }

        .body-cellKh {
          font-family: $font-siemreap;
          font-size: $font-size-body;
          padding: 6px 20px;
        }

        .body-cellKh-endKh {
          font-family: $font-siemreap;
          font-size: $font-size-body;
          padding: 6px 30px 6px 0px;
        }

        //English
        .body-cell-start {
          font-size: $font-size-body;
          padding: 6px 0px 6px 20px;
        }

        .body-cell {
          font-size: $font-size-body;
          padding: 6px 20px;
        }

        .body-cell-end {
          font-size: $font-size-body;
          padding: 6px 30px 6px 0px;
        }
      }
    }
  }
}