@import "../themes.scss";

.delete-container {
  .MuiPaper-root {
    border-radius: 12px !important;
    width: 400px !important;
  }

  .dialog-title {
    font-size: $font-size-subtitle;
    color: $primary-color;
    font-weight: bold;
  }

  .titleKh {
    font-family: $font-siemreap;
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold;
    padding: 0px;
  }

  .titleEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold;
    padding: 0px;

  }

  .close-icon {
    color: red;
  }

  .simple-text {
    font-size: $font-size-subtitle;
  }
  .body-text {
    color: $primary-color;
    font-size: $font-size-subtitle;
    font-weight: bold;
  }

  .field-title {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-body;
  }

  .action-container {
    width: 100%;
    margin: 10px 16px 10px 16px;
    .btn-cencel {
      background-color: $primary-background !important;
      color: #000;
      font-family: $font-title;
    }

    .btn-delete {
      background-color: $background-delete !important;
      color: #fff;
      font-family: $font-title;
    }
  }

  .action {
    padding-bottom: 20px;
    padding-left: 18px;
    padding-right: 18px;

    .btn-deleteKh {
      font-size: $font-size-subtitle;
      font-weight: bold;
      font-family: $font-siemreap;
    }

    .btn-deleteEn {
      font-size: $font-size-subtitle;
      font-weight: bold;
      font-family: $font-title;
    }

    .btn-voidKh {
      background-color: red;
      color: white;
      border: none;
      font-weight: bold;
      font-family: $font-siemreap;
    }

    .btn-voidEn {
      background-color: red;
      color: white;
      border: none;
      font-weight: bold;
      font-family: $font-title;
      font-size: $font-size-subtitle;
    }
  }

}
