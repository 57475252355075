@import "../../../../themes.scss";

.create-discount {
  .MuiPaper-root {
    border-radius: 12px !important;
    width: 500px !important;
  }

  .dialog-title {
    .title {
      font-size: $font-size-title;
      color: $primary-color;
      font-weight: bold;
    }

    .clear-icon {
      color: red;
    }
  }

  .field-title {
    font-family: $font-title;
    font-size: $font-size-body;
  }

  .profile {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .avater-image {
      width: 140px;
      height: 140px;
    }

    .field-title {
      font-family: $font-title;
      font-size: $font-size-body;
    }
  }

  .btn-action {
    width: 100%;
    margin: 0px 16px 20px 16px;
    background-color: $primary-color !important;
    font-family: $font-title;
    font-size: $font-size-body;
    color: #fff;
  }
}