@import "../../themes.scss";

.titleKh {
  padding: 10px;
  border: none;
  // font-family: $font-body;
  font-size: $font-size-title;
  font-weight: bold;
  text-align: center;
  color: $primary-color;
}

.titleEn {
  padding: 10px;
  border: none;
  font-family: $font-title;
  font-size: $font-size-title;
  font-weight: bold;
  text-align: center;
  color: $primary-color;
}

.subKh {
  // font-family: $font-body;
  font-size: $font-size-subtitle;
}

.subEn {
  font-family: $font-title;
  font-size: $font-size-subtitle;
}
