@import "../../themes.scss";

.create-refund {
  font-family: $font-title;
  position: relative;

  .MuiPaper-root {
    border-radius: 12px !important;

    // width: 500px !important;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .voided {
    border: 5px solid #ff000073;
    border-radius: 15px;
    padding: 10px;
    position: absolute;
    font-size: 100px;
    font-weight: bold;
    color: #ff000073;
    top: 37%;
    left: 37%;
    transform: translate(-50%, -50%);
    -webkit-transform: rotate(-35deg);
    -moz-transform: rotate(-35deg);
    -o-transform: rotate(-35deg);
    writing-mode: lr-tb;
    z-index: 99;
  }

  .dialog-title {
    padding: 10px !important;

    .title {
      font-size: $font-size-title;
      color: $primary-color;
      font-weight: bold;
    }

    .clear-icon {
      color: red;
    }
  }

  .titleKh {
    font-family: $font-siemreap;
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold;
  }

  .titleEn {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold;
  }

  .invoice {
    color: $primary-color;
    font-family: $font-title;
    font-weight: bold;
  }

  .table {
    .header-row {
      .header-cell {
        padding: 10px 0px !important;
        font-weight: bold;
        color: $primary-color;
        font-family: $font-title;
        border-color: $primary-color;
      }
    }
  }

  .return-item {
    .header-cell {
      font-weight: bold;
      color: $primary-color;
    }
  }

  .return {
    color: rgb(196, 196, 1);
    font-weight: bold;
  }

  .refund {
    color: green;
    font-weight: bold;
  }

  .action {
    padding: 0px 16px 16px 16px;
    width: 100%;

    .btn-actionKh {
      background-color: $primary-color;
      color: #fff;
      font-family: $font-siemreap;
    }

    .btn-actionEn {
      background-color: $primary-color;
      color: #fff;
      font-family: $font-title;
    }
  }
}

// getItemById component
.body-row-refund {
  cursor: pointer;

  .body-cell {
    padding: 4px 0px !important;
    font-family: $font-title;

    .image {
      width: 50px !important;
    }
  }
}
