@import "../../../themes.scss";

// ======================= Order container Card ================
.box-order-card {
  height: 300px;
  // padding: 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.order-item-container {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  border-radius: 10px;
  padding-right: 10px;
  position: relative;

  .grid-1 {
    .accordion {
      box-shadow: none;
    }
    .img-container {
      height: auto;
      border-radius: 5px;
      .product-img {
        width: auto;
        height: 40px;
      }
    }
  }
  .grid-2 {
    display: flex-end;
    // justify-content: end;
    align-items: center;
    .descrease-container {
      background-color: rgb(240, 127, 127) !important;
      .descrease-icon {
        font-size: 16px;
        color: #fff;
      }
    }
    .increase-container {
      background-color: $primary-color !important;
      .increase-icon {
        font-size: 16px;
        color: #fff;
      }
    }
  }

  .qty-product-sale {
      background-color: none !important;
      color: black !important;
  }
  // .product-name {
  //   font-family: $font-title;
  //   font-size: $font-size-body;
  //   color: $primary-color;
  //   font-weight: bold;
  // }

  // .product-price {
  //   font-family: $font-title;
  //   font-size: $font-size-body;
  //   color: $primary-color;
  //   font-weight: bold;
  // }
  // .descrease-container {
  //   background-color: rgb(240, 127, 127) !important;
  //   .descrease-icon {
  //     font-size: 16px;
  //     color: #fff;
  //   }
  // }
  // .product-qty {
  //   font-family: $font-title;
  //   font-size: $font-size-body;
  //   font-weight: bold;
  // }
  // .increase-container {
  //   background-color: $primary-color !important;
  //   .increase-icon {
  //     font-size: 16px;
  //     color: #fff;
  //   }
  // }
  .delete-btn {
    font-size: 25px;
    color: red;
    cursor: pointer;
  }
}
