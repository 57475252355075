@import "../../themes.scss";
//================= DAILY REPORT ======================
.daily-report {
  margin-top: 5px;
}

//================= CLOSE MONEY REPORT ======================
.money-content {
  .left-table {
    width: 100%;
    border-collapse: collapse !important;
    .thead {
      border: 0.8px solid #b2cff0 !important;
      background-color: #cfe1f8;
      font-weight: normal;
      vertical-align: center;
      padding-top: 4px;
      padding-bottom: 4px;
      .th {
        border: 0.8px solid #b2cff0 !important;
        background-color: #cfe1f8;
        font-weight: normal;
        vertical-align: center;
        padding-top: 4px;
        padding-bottom: 4px;
      }
      .td {
        border: 0.8px solid #b2cff0 !important;
        font-weight: normal;
        vertical-align: center;
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
    .center {
      text-align: center;
    }
    .balance,
    .total-revenue,
    .total-disbursement {
      background-color: #deebf7;
      .blue {
        color: #080eff;
        text-align: center;
      }
    }
    .amount-after-payment {
      background-color: #fff2cc;
      color: #c00000;
      text-align: center;
    }
  }

  .right-table {
    width: 100%;
    border-collapse: collapse !important;
    .right {
      text-align: right;
    }
    .total {
      background-color: #fff2cc;
      color: #c00000;
    }
  }
}
