@import "../../../themes.scss";

.print-pos {
  position: relative;

  // .MuiPaper-root {
  //   border-radius: 12px !important;
  // }

  .btn-pay-usd-pos {
    color: black;     
    font-size: 17px;  
    height: 90%; 
    margin-top: 12px;
    margin-bottom: 12px; 
    margin-right: 10px;
  }
  

  .voided {
    border: 5px solid #ff000073;
    border-radius: 15px;
    padding: 10px;
    position: absolute;
    font-size: 100px;
    font-weight: bold;
    color: #ff000073;
    top: 37%;
    left: 37%;
    transform: translate(-50%, -50%);
    -webkit-transform: rotate(-35deg);
    -moz-transform: rotate(-35deg);
    -o-transform: rotate(-35deg);
    writing-mode: lr-tb;
    z-index: 99;
  }

  .dialog-title {
    .title {
      font-size: $font-size-title;
      color: $primary-color;
      font-weight: bold;
    }

    .clear-icon {
      color: red;
    }
  }

  .field-title {
    font-family: $font-title;
    font-size: $font-size-body;

    .MuiOutlinedInput-root .MuiSvgIcon-root {
      display: none !important;
    }
  }

  .paid {
    color: $primary-color !important;

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border: 1px solid $primary-color !important;
    }

    .MuiOutlinedInput-root .MuiSvgIcon-root {
      color: $primary-color !important;
    }
  }

  .unpaid {
    color: red !important;

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border: 1px solid red !important;
    }

    .MuiOutlinedInput-root .MuiSvgIcon-root {
      color: red !important;
    }
  }

  .owe {
    color: #f5cf00 !important;

    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border: 1px solid #f5cf00 !important;
    }

    .MuiOutlinedInput-root .MuiSvgIcon-root {
      color: #f5cf00 !important;
    }
  }

  .confirm{
    font-size: 11px !important;
  }

  .btn-action {
    width: 100%;
    margin: 0px 16px 20px 16px;
    background-color: $primary-color !important;
    font-family: $font-title;
    font-size: $font-size-body;
    color: #fff;
  }


}