.App {
  text-align: center;
}

/*  style hidden increase decrease text field type number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/*   */

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: GothicBoldRegular;
  src: url("./Assets/font/GothicBoldRegular.ttf");
}

@font-face {
  font-family: KhmerOsMuol;
  src: url("./Assets/font/KhmerOSmuollight.ttf");
}

@font-face {
  font-family: Siemreap;
  src: url("./Assets/font/KhmerOSsiemreap.ttf");
}

@font-face {
  font-family: Bayon;
  src: url("./Assets/font/Bayon.ttf");
}

.title-summary {
  width: 100%;
  font-weight: bold !important;
  background-color: rgb(232, 229, 229);
  font-size: 12px;
  font-family: Siemreap;
}

.tackteng-font {
  display: flex;
  justify-content: center;
}

.image-tackteng {
  width: 60%;
}

.logo-company {
  width: 120px;
}

.body-title-print {
  text-align: center;
  font-size: 13px;
  color: #000;
  font-family: KhmerOsMuol;
  margin: -10px 0px 10px 0px;
}

.loan-header-title {
  font-size: 13px;
  font-family: Siemreap;
  color: #000;
}

.loan-header-item {
  font-size: 13px;
  font-family: Siemreap;
  color: #000;
}

.loan-contact-info {
  text-align: center;
  font-size: 13px;
  color: #000;
  font-family: Siemreap;
  font-weight: bold;
}

/* ============================= loan table =============================== */

.print-loan-container {
  padding: 30px 50px;
}

.wingding {
  font-size: 10px;
  color: #000;
  text-align: center;
}

.summary-center-kh {
  font-size: 12px;
  color: #000;
  font-family: KhmerOsMuol;
  text-align: center;
}

.summary-center-eng {
  text-align: center;
  font-family: GothicBoldRegular;
  font-size: 10px;
  color: #000;
  line-height: 180%;
  text-transform: none;
}

.number-title-customerbyday {
  font-size: 13px !important;
  color: #000;
  font-family: Siemreap !important;
}

.number-item-customerbyday {
  font-size: 13px !important;
  color: #000;
  font-family: Century Gothic !important;
}

.loan-table-container {
  padding: 10px 0px;
}

.loan-table {
  width: 100%;
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

.loan-table tr th {
  border: 1px solid #cdd7f5;
  font-family: Siemreap !important;
  padding: 4px;
  font-size: 11px;
  color: #000;
}

.loan-table tr td {
  border: 1px solid #cdd7f5;
  text-align: center;
  padding: 2px;
  font-size: 11px;
  color: #000;
  font-family: Siemreap !important;
}

.no-loan-total {
  color: red;
}

.date-loan-total {
  color: red;
}

.amount-loan-total {
  color: red;
}

.interest-loan-total {
  color: red;
}

.loan-footer-title {
  text-align: center;
  font-size: 13px;
  color: #000;
  font-family: Siemreap;
}

.loan-footer-name {
  text-align: center;
  font-size: 12px;
  color: #000;
  font-family: KhmerOsMuol;
}

/* =============================== print doc request ========================= */

/* ====================================== loan details print ================================ */

.loan-request-font-tackteng {
  display: flex;
  justify-content: center;
}

.loan-request-image-tackteng {
  width: 80%;
  margin-top: -8px;
}

.print-loan-request-container {
  padding: 30px 60px 20px 60px !important;
}

.body-title-loant-request {
  text-align: center;
  font-size: 14px;
  color: #000;
  font-family: KhmerOsMuol !important;
  margin: -10px 0px 10px 0px;
}

.loan-request-borrower-title {
  font-size: 14px;
  font-family: KhmerOsMuol !important;
  color: #000;
  margin-top: 3px !important;
}

.loan-request-title-bold {
  font-size: 15px;
  font-family: Siemreap !important;
  font-weight: bold;
  color: #000;
  margin-top: 3px !important;
}

.loan-request-title {
  font-size: 15px;
  font-family: Siemreap !important;
  color: #000;
  margin-top: 3px !important;
}

.loan-request-item {
  /* width: auto; */
  text-align: center;
  font-size: 15px;
  font-family: Siemreap !important;
  color: #000;
  margin-top: 3px !important;
  /* text-decoration: dotted underline !important; */
}

.loan-request-divider-footer {
  width: 100%;
  height: 7px;
  background-color: #96bada;
}

.loan-request-contact-footer {
  width: 100%;
  font-size: 12px;
  font-family: Siemreap !important;
  color: #8489f3;
  text-align: center;
}

.loan-request-title-bottom {
  font-size: 15px;
  font-family: Siemreap !important;
  text-align: center;
  color: #000;
}

/* ====================================== equiment loan print ================================ */

.loan-agreement {
  font-size: 15px;
  font-family: Siemreap !important;
  text-align: justify;
  margin-top: 3px !important;
}

.equipment-table {
  width: 100%;
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

.equipment-table tr th {
  border: 1px solid #cdd7f5;
  font-family: Siemreap !important;
  padding: 2px;
  font-size: 15px;
}

.equipment-table tr td {
  border: 1px solid #cdd7f5;
  text-align: center;
  padding: 10px;
  font-size: 15px;
}

.loan-equiment-title {
  text-align: center;
  font-size: 15px;
  font-family: Siemreap !important;
  color: #000;
  padding: 10px;
}

/* ============================= loan table agreement =============================== */
.loan-table-container {
  padding: 10px 0px;
}

.loan-table-agreement {
  width: 100%;
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

.loan-table-agreement tr th {
  border: 1px solid #cdd7f5;
  font-family: Siemreap !important;
  padding: 6px 65px;
  font-size: 15px;
  color: #000;
  font-weight: normal;
}

.loan-table-agreement tr td {
  border: 1px solid #cdd7f5;
  text-align: left !important;
  padding: 6px 10px;
  font-size: 15px;
  color: #000;
}

/* ============================================ report page ============================================ */

.setup-company-container {
  background-color: #fff;
  padding: 40px;
}

/* ============================= company table =============================== */
.print-company-container {
  padding: 30px;
}

.company-table-container {
  padding: 10px 0px;
}

.company-table {
  width: 100%;
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

.company-table tr th {
  border: 1px solid #cdd7f5;
  font-family: Siemreap !important;
  padding: 4px;
  font-size: 12px;
}

.company-table tr td {
  border: 1px solid #cdd7f5;
  font-family: Siemreap !important;
  padding: 4px;
  font-size: 12px;
  text-align: center;
  font-weight: 100 !important;
}

.company-title {
  font-size: 12px;
  color: #000;
  font-family: KhmerOsMuol;
  text-align: center;
}

/* ============================================= Menu change font ============================================ */

.menuKh {
  font-family: Siemreap !important;
}

.menuEn {
  font-family: Century Gothic !important;
  /* font-weight: bold !important; */
}

.laguage-title {
  font-family: Century Gothic !important;
  /* font-weight: bold !important; */
  color: rgb(17, 17, 17) !important;
}

.username-login {
  color: #38b14a !important;
  text-transform: none !important;
  font-family: Century Gothic !important;
  font-weight: bold !important;
  text-transform: capitalize !important;
}

/* =========================================== Print Receive============================================= */
.print-receipt-container {
  margin-top: 10px;
}

.logo-invoice-print {
  width: 25px;
}

.logo-com {
  height: 55px;
  position: absolute;
  left: 0px;
}

.logo-com-print {
  height: 60px;
  position: absolute;
  left: 0px;
  top: 0px;
}

.invoice-header-top-print {
  font-size: 9px !important;
  font-weight: bold !important;
  text-align: center !important;
  color: #000 !important;
  /* background-color: red; */
}

.color-black-text {
  color: #000 !important;
  font-size: 9px !important;
  font-weight: bold !important;
}

.invoice-header {
  font-size: 10px !important;
  font-weight: bold !important;
}

.invoice-header tbody tr td {
  font-weight: bold;
  font-size: 10px !important;
  padding: 0px;
}

.receipt-title-kh {
  font-size: 17px !important;
  font-family: KhmerOsMuol !important;
  /* letter-spacing: 2px; */
  /* color: #0969a0 !important; */
  color: #000 !important;
  text-align: center !important;
}

.receipt-title {
  font-size: 15px !important;
  /* font-family: GothicBoldRegular !important; */
  /* font-weight: bold !important; */
  /* color: #0969a0 !important; */
  color: #000 !important;
  text-align: center !important;
}

.receipt-subtitle {
  font-size: 11px !important;
  font-weight: bold !important;
  color: #000 !important;
}

.receipt-footer {
  font-size: 10px !important;
  font-weight: bold !important;
  text-align: center !important;
  color: #000 !important;
}

.receipt-table-container {
  width: 100%;
}

.receipt-table {
  width: 100%;
}

.receipt-table thead tr th {
  /* font-weight: bold !important; */
  /* border-top: 1px solid #000; */
  border-bottom: 1px solid #000;
  font-family: GothicRegular !important;
  padding: 1px 0px;
  font-size: 9px !important;
  color: #000;
}
.receipt-table .start {
  text-align: start;
}
.receipt-table .discription {
  text-align: start;
  padding-left: 0px;
}
.receipt-table .end {
  text-align: end;
  padding-left: 10px;
  /* width: 1% !important; */
}
.receipt-table .center {
  text-align: center;
}
.receipt-table .end-line {
  border-bottom: 1px solid #7f7f7f !important;
}

.receipt-table .top-line {
  border-top: 1px solid #7f7f7f !important;
}

.receipt-table tbody tr {
  /* font-weight: bold !important; */
  padding: 4px 0px !important;
  font-size: 11px !important;
}

.receipt-table tbody tr td {
  font-weight: bold !important;
  color: #000;
  font-size: 9px !important;
}

.confirm-person {
  font-weight: bold !important;
  font-size: 9px !important;
  color: #000 !important;
}

.bold {
  font-weight: bold !important;
}
.note {
  font-size: 11px !important;
  padding: none !important;
  width: 100%;
  resize: none;
  border: none;
  overflow: hidden;
  font-family: GothicRegular !important;
}
.note:hover {
  background-color: #edf7fd !important;
}
.note:focus {
  background-color: #edf7fd !important;
  outline: none !important;
}

.btn-void-payment-card-void {
  color: rgb(108, 107, 107) !important;
}

/* ==================================Print table ============================ */
.report-table-container {
  margin-top: 10px;
  height: 500px;
  /* height: 540px; */
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  border-collapse: separate;

}
.daily-report-print {
  width: 100%;
  position: relative;
  border-collapse: collapse;
}
.thead-sticky {
  font-weight: normal;
  top: 0;
  position: sticky !important;
  
}
/* .throw-head{
  border: 1px solid red;
} */
.thead-title-in-report {
  border: 1px solid #b2cff0 !important;
  background-color: #cfe1f8;
  font-weight: normal;
  vertical-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding: 4px 0px 4px 0px;
  border-collapse: collapse !important;
}
.tbody-title-in-report {
  border: 1px solid #b2cff0 !important;
  font-weight: normal;
  vertical-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
}
@media print {
  .report-table-container {
    overflow-x: visible;
    overflow-y: visible;
  }
  .thead-title-in-report {
    border: 1px solid #b2cff0 !important;
  }
}

/* ===========================================Swiper slide =============================== */
.swiper {
  cursor: pointer;
  position: relative;
  justify-content: center;
  align-items: center;
  max-width: 700px;
  height: 100%;
  margin: auto;
  object-fit: cover;
}

.swiper-slide {
  object-fit: cover;
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  width: 100%;
  display: block;
  height: 600px;
  object-fit: cover;
}


/* ==================================End Print table ============================ */

/* .td-active {
  text-align: center;
  padding: 2px;
  font-size: 11px;
  color: #000;
  font-family: GothicRegular !important;
  font-weight: 100 !important;
}

.td-not-active-name {
  background-color: #fff;
  padding: 2px 2px 0px 6px;
  font-size: 11px;
  color: #000;
  font-family: GothicRegular !important;
  font-weight: 100 !important;
}

.td-not-active-total {
  background-color: #fff;
  text-align: right;
  padding: 2px 2px 0px 6px;
  font-size: 11px;
  color: #000;
  font-family: GothicRegular !important;
  font-weight: 100 !important;
}

.td-active-name {
  text-align: left;
  padding: 2px 2px 0px 6px;
  font-size: 11px;
  color: #000;
  font-family: GothicRegular !important;
  font-weight: 100 !important;
}

.td-active-total {
  text-align: right;
  padding: 2px 2px 0px 6px;
  font-size: 11px;
  color: #000;
  font-family: GothicRegular !important;
  font-weight: 100 !important;
}

.td-total {
  text-align: right;
  font-size: 11px;
  padding: 2px 6px 2px 0px;
  color: #000;
  font-family: GothicRegular !important;
  font-weight: 100 !important;
} */
