// -----------Color
$primary-color: #0F81C2;
$secondary-color: #38b14a;
$primary-background: #EBEDEF;
$page-title-color: #707070;
$background-table-active: #f4f6f6;
$background-create: #6e65e6;
$background-delete: #f26469;

$mask-primary:#dceaf6;

//background Color
$background-menu: linear-gradient(
  180deg,
  rgba(255, 255, 255, 1) 20%,
  rgba(221, 233, 243, 1) 100%
);
$text-menu-not-active: rgb(109, 108, 108);
$background-menu-active: #efeefc;

//font
$font-title: "Century Gothic";
$font-siemreap: "Siemreap" !important;
$font-kh-bayon: "Bayon" !important;

//font-size
$font-size-title: 22px;
$font-size-subtitle: 18px;
$font-size-body: 15px;
// Login Page

@mixin desktop {
  @media (min-width: #{1025px}) and (max-width: #{1650px}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{769px}) and (max-width: #{1024px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{426px}) and (max-width: #{768px}) {
    @content;
  }
}

@mixin mobile-large {
  @media (min-width: #{426px}) and (max-width: #{600px}) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{377px}) and (max-width: #{425px}) {
    @content;
  }
}

@mixin mobile-small {
  @media (min-width : #{0px}) and (max-width: #{376px}) {
    @content;
  }
}
