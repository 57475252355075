@import "../../../themes.scss";

.view-qrcode {
  .MuiPaper-root {
    border-radius: 12px !important;
  }

  .dialog-title {
    .title {
      font-size: $font-size-title;
      color: $primary-color;
      font-weight: bold;
    }

    .clear-icon {
      color: red;
    }
  }

  .body-container {
    width: 100%;
    padding-top: 20px;
    // height: 400px;

    .qrcode-container {
      width: 100%;
      height: 100%;
      // font-size: 15px;
    }
  }
}
