@import ".././themes.scss";

.pos-page {
  height: 100vh;
  // width: 100% !important;
  overflow-y: auto;
  overflow-x: auto;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    display: none;
  }

  .slash {
    width: 4px;
    height: 50px;
    background-color: $primary-color;
  }

  .page-title {
    // font-family: $font-title;
    // color: $page-title-color;
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold;
  }

  .search-container {
    display: flex;
    justify-content: left;

    .box-search {
      .text-field {
        background-color: #fff;
        border-radius: 10px;
        font-family: $font-title;

        .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
          border-color: #fff;
          font-family: $font-title;
          border-radius: 10px;
        }

        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
          color: black;
          font-family: $font-title;
          border-radius: 10px;
        }

        .MuiInputLabel-root.Mui-focused {
          color: black;
          font-family: $font-title;
          border-radius: 10px;
        }
      }
    }
  }

  .category {
    width: 150px;
    font-size: 20px;
    padding: 10px 0px;
    font-weight: bold;
  }

  .category-style {
    width: 100% !important;

    .scroll-container {
      display: flex;
      flex-direction: row;
      border-radius: 0px 12px 12px 12px;
      scroll-behavior: smooth;
      overflow-x: auto;
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }

      .item-box {
        line-height: none !important;
        background-color: #fff;
        text-transform: none;
        margin-right: 10px;
        min-width: 15%;
        // min-width: 110px;
        // height: 117px;
        padding: 0px !important;

        .menu-icon {
          font-size: 30px;
        }

        .active {
          width: 100% !important;
          color: #fff;
          background-color: $primary-color;
          border-radius: 5px;
          margin-top: 5px;
        }

        .no-active {
          background-color: none;
        }
      }
    }
  }

  .add-container {
    // display: flex;
    // justify-content: right;

    .btn-add {
      background-color: $primary-color;
      color: #fff;
      border-radius: 10px;
      padding: 8px 30px;
    }
  }

  .tabs-container {
    width: 100%;
    max-height: 450px;
    // height: 70%;
    overflow-y: auto;
    padding: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    //card
    .btn-pos {
      width: 100%;
      position: relative;
      text-transform: none;
      text-align: left;
      border-radius: 5px;
      padding: 12px !important;
      // transition: 0.8s;
      background-color: #ffffff;
      box-shadow: 0px 1px 7px rgba(38, 38, 38, 0.2);

      &:hover {
        // transition: all 0.2s ease-out;
        box-shadow: none;
      }

      .stock-out {
        position: absolute;
        font-weight: bold;
        font-size: $font-size-title;
        border-radius: 5px;
        color: rgb(255, 255, 255);
        padding: 5px;
        background-color: red;
        // background-color: $primary-color;
        font-size: 8px;
        top: -10px;
        right: -10px;
      }

      .img-container {
        height: 60px;
        background-color: #ffffff;
        border-radius: 5px;

        .product-img {
          width: auto;
          height: 100% !important;
        }

      }

      .card-body {
        margin-top: 5px;   
        height: 55px;   
        background-color: #fff;
        justify-content: space-between;

        .product-name {
          font-size: 9px;
          color: black;
          font-weight: bold;
        }

        .product-price {          
          margin-top: 5px;
          text-align: start;
          // font-family: $font-title;
          font-size: 10px;
          color: $primary-color;
          font-weight: bold;
        }

        .unit-name {          
          margin-top: 6px;
          font-size: 9px;
          color: #000000;
          font-weight: bold;
        }
        
      }
    }
  }

  //GRID 2
  .order-container {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    
    .clear-btn {
      position: absolute;
      top: 0px;
      right: 0px;
      color: #fff;
      border-radius: 0px !important;
      border-top-right-radius: 10px !important;
      background-color: $primary-color;
    }

    .order-title {
      font-family: $font-title;
      font-size: $font-size-title;
      color: $primary-color;
      font-weight: bold;
    }

    .order-subtitle {
      font-family: $font-title;
      font-size: $font-size-body;
      color: gray;
      font-weight: bold;
    }

    // ======================= Total ===================================
    .total-container {
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
      padding: 20px;
      border-radius: 10px;

      .sub-total {
        font-family: $font-title;
        font-size: $font-size-body;
        color: gray;
        font-weight: bold;
      }

      .underline {
        border-bottom: 2px gray dashed;
      }

      .total {
        font-family: $font-title;
        font-size: $font-size-subtitle;
        color: $primary-color;
        font-weight: bold;
      }

      .total-kh {
        font-family: $font-siemreap;
        font-size: $font-size-subtitle;
        color: $primary-color;
        font-weight: bold;
      }

    }

    .btn-payment {
      background-color: $primary-color;
      color: #fff;
      border-radius: 10px;
      padding: 10px 0px;
      text-align: center;
    }
  }
}