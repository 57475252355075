@import "../themes.scss";

.icon-mash {
  color: $primary-color;
  width: 100px;
}


.marsk{
    font-weight: bold;
    font-size: 30;
    line-height: 2.5;
    color: $primary-color;
    text-decoration: underline;
}

.back-text{
    color:#fff;
    font-family: $font-title;
    font-weight: bold;
}
.btn-home{
    color: #fff;
    border-radius: 10px;
    background-color: $primary-color;
    // width: 80px;
    // margin: 10px 0px;
    font-size: 12px;
  
    

}