@import "../themes.scss";

.more-icon {
  color: $primary-color;
}

.update-icon {
  color: $background-create;
}
.delete-icon {
  color: red;
}
.delete-message {
  font-family: $font-title;
  font-size: $font-size-body;
  color: $primary-color;
}
.update-title {
  font-family: $font-title;
  font-size: $font-size-subtitle;
}
