@import "../themes.scss";

.login-page {
  width: 100%;
  height: 100vh;
  background-color: $background-menu;

  .background-image {
    background-image: url("../Assets/angkor-picture.png");
    background-repeat: no-repeat;
    // background-size: 80%;
    background-size: 100%;
    background-position: 50% 50%;
    // filter: blur(1px);
    position: fixed;
    // opacity: 0.2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .text-field {
    background-color: #fff;
    border-color: $primary-color;
    border-radius: 20px;

    .MuiOutlinedInput-input {
      color: black !important;
    }
    .MuiInputLabel-root {
      color: black !important;
      // border-radius: 20px;
    }
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color;
      border-radius: 20px;
    }
    :hover .MuiOutlinedInput-input {
      color: #fff;
    }
    :hover .MuiInputLabel-root {
      color: black;
      border-radius: 20px;
    }
    :hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color;
      border-radius: 20px;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      color: black;
    }
    .MuiInputLabel-root.Mui-focused {
      color: black;
      border-radius: 20px;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color;
      border-radius: 20px;
    }
  }

  .container {
    width: 100%;
    height: 95%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;


    .form-login {
      background-color: #fff;
      border-radius: 20px;
      padding: 20px;


      .box-logo {
        display: flex;
        justify-content: center;
      }

      .box-text {
        display: flex;
        justify-content: center;

        .title-login {
          .title {
            color: #000;
            font-weight: bold;

            @include mobile-large {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .sub-title {
            color: #000;
            text-align: center;
          }
        }
      }

      .box-login {
        display: flex;
        justify-content: center;

        .icon {
          color: $primary-color;
          cursor: pointer;
        }

        .btb-sign-in {
          // background-color: #fff !important;
          color: #fff;
          font-weight: bold;
          // font-size: 18px;
          border-radius: 20px;
          background-color: $primary-color;
        }
      }
    }
  }
}
