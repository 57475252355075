@import "../themes.scss";

.create-container {


  .MuiPaper-root {
    border-radius: 20px !important;
    width: 450px;
    
    @include mobile-small {
      width: 310px !important;
    }

    @include mobile {
      width: 370px !important;
    }

    @include mobile-large {
      width: 476px !important;
    }
  }

  .dialog-title {
    font-size: $font-size-subtitle;
    color: $primary-color;
    font-weight: bold;
  }
  .close-icon {
    color: red;
  }

  .field-title {
    font-family: $font-title;
    color: $primary-color;
    font-size: $font-size-body;
  }

  .titleKh {
    font-family: $font-siemreap;
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold;
  }

  .titleEn {
    color: $primary-color;
    font-size: $font-size-title;
    font-weight: bold;
  }

  .subKh {
    font-family: $font-siemreap;
    font-size: $font-size-subtitle;
    color: #000;
  }

  .subEn {
    font-size: $font-size-subtitle;
    color: #000;
  }

  .grid-profile {
    .profile {
      display: flex;
   
      cursor: pointer;
      .avater-image {
        width: 140px;
        height: 140px;
      }
    }
    .text-Kh {
      font-family: $font-siemreap;
      font-size: $font-size-subtitle;
      font-weight: bold;
      color: $primary-color;
    }

    .text-En {
      font-family: $font-title;
      font-size: $font-size-subtitle;
      font-weight: bold;
      color: $primary-color;
    }
  }

  .avater-image {
    width: 140px;
    height: 140px;
  }

  .text-field {
    width: 100%;
    font-family: $font-title;
    background-color: #fff;
    margin-left: 20px;
    color: $primary-color;
    font-weight: bold;


    .MuiOutlinedInput-root{
      color: $primary-color;
      font-weight: bold;
    }
  
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      font-family: $font-title;
      border-color: #fff;
      padding: 10px;
      color: $primary-color;
      font-weight: bold;
      
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input {
      font-family: $font-title;
      border-radius: 10px;
      border-color: #fff;
      padding: 10px;
      color: $primary-color;
      font-weight: bold;
    }
    .MuiInputLabel-root.Mui-focused {
      font-family: $font-title;
      border-color: #fff;
      padding: 10px;
      color: $primary-color;
      font-weight: bold;
    }
  }

  .action-container {
    width: 100%;
    margin: 10px 16px 10px 16px;
    .btn-cencel {
      background-color: $primary-background !important;
      color: #000;
      font-family: $font-title;
    }

    .btn-create {
      background-color: $background-create !important;
      color: #fff;
      font-family: $font-title;
    }
  }

  .action {
    padding: 0px 16px 16px 16px;
    width: 100%;
    display: flex;
    justify-content: center;
    .btn-actionKh {
      width: 100%;
      background-color: $primary-color;
      color: #fff;
      font-family: $font-siemreap;
    }

    .btn-actionEn {
      width: 100%;
      background-color: $primary-color;
      color: #fff;
    }
  }
}
